<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table :headers="headers" :items="desserts" class="elevation-1">
                    <template v-slot:item.action="{ item }">
                        <v-btn fab dark small color="success" @click="NextDetail(item.UserId)">
                            <v-icon dark>fa fa-pen</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-switch v-model="item.SettingAutenPassStatus" @change="SubmitUser(item)" class="ma-2"></v-switch>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data() {
            return {
                headers: [
                    {
                        text: "ชื่อผู้ใช้",
                        align: "left",
                        sortable: true,
                        value: "UserName"
                    },
                    {
                        text: "เบอร์โทรศัพท์",
                        align: "left",
                        sortable: true,
                        value: "UserTel"
                    },
                    {
                        text: "สาขา",
                        align: "left",
                        sortable: true,
                        value: "OrgList"
                    },
                    { width: 50, text: "", value: "action", sortable: false },
                    { width: 50, text: "สถานะ", value: "status", sortable: false }
                ],
                desserts: []
            };
        },
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    this.Select();
                } else {
                    this.$router.push("/Member");
                }
            });
        },
        methods: {
            Select() {
                axios.get("Api/V1/Select/Select/User?Level=Boss").then(res => {
                    if (res.status == 200) {
                        this.desserts = res.data;
                    }
                });
            },
            NextDetail(v) {
                this.$router.push("Setting/Detail?UserId=" + v);
            },
            SubmitUser(v) {
                axios
                    .put("/Api/V1/Update/AcceptPassStatus/Setting", v)
                    .then(() => {
                        this.Select();
                    })
                    .catch(() => {
                        this.Select();
                    });
            }
        }
    };
</script>
<style></style>
